import { useNavigate } from '@remix-run/react';
import { useSessionStorageState } from 'ahooks';
import { useEffect } from 'react';

const FIRST_LANDING_URL_STORAGE_KEY = 'firstLandingUrl';

const isExternalReferrer = () => {
    if (!document.referrer) {
        return false;
    }
    const url = new URL(document.referrer);
    return url.hostname !== location.hostname;
};

export const useClickBack = (navUrl = '/') => {
    const navigate = useNavigate();

    const [firstLandingUrl, setFirstLandingUrl] =
        useSessionStorageState<string>(FIRST_LANDING_URL_STORAGE_KEY, {
            defaultValue: '',
        });

    const onClick = () => {
        if (isExternalReferrer()) {
            navigate(navUrl);
        } else if (document.location.pathname === navUrl) {
            navigate('/');
        } else if (firstLandingUrl === document.location.href) {
            setFirstLandingUrl('');
            navigate(navUrl);
        } else {
            navigate(-1);
        }
    };

    useEffect(() => {
        if (!firstLandingUrl && !document.referrer) {
            setFirstLandingUrl(document.location.href);
        } else if (!firstLandingUrl || isExternalReferrer()) {
            setFirstLandingUrl(document.location.href);
        }
    }, [firstLandingUrl, setFirstLandingUrl]);

    return {
        onClick,
    };
};
